
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import OptionTable from '@/components/option/OptionTable.vue';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import OptionArticleSimpleTable from '@/components/option/article/OptionArticleSimpleTable.vue';
import { OptionArticle } from '@/interfaces/models/OptionArticle';

const venue = namespace('venue');
const foodcard = namespace('foodcard');

@Component({
  components: { VEmptyState, OptionTable, VWrapper, OptionArticleSimpleTable },
})
export default class Category extends Vue {
  @venue.State('active') public venue!: Venue;

  @foodcard.Action('setOptionFilter') public setOptionFilter!: any;
  @foodcard.Action('makeOptionVisible') public makeOptionVisible!: any;
  @foodcard.Action('hideOption') public hideOption!: any;
  @foodcard.State('optionFilter') public optionFilter!: Filter;
  @foodcard.State((state) => state.optionPagination.total) public total!: number;
  @foodcard.Action('fetchPaginatedOptions') public getOptions!: any;
  @foodcard.Action('destroyOption') protected deleteOption!: any;
  @foodcard.Action('destroyOptions') protected deleteOptions!: (data: { ids: string[] }) => {};
  @foodcard.State('options') protected items!: OptionGroup[];
  @foodcard.Action('setActiveOption') protected setActiveOption!: any;
  @foodcard.Action('resetOptions') protected resetOptions!: any;

  @foodcard.Action('deactivateOptionArticle') public deactivateOptionArticle!: any;
  @foodcard.Action('activateOptionArticle') public activateOptionArticle!: any;

  @foodcard.Action('makeArticleOptionVisible') public makeArticleOptionVisible!: any;
  @foodcard.Action('hideOptionArticle') public hideOptionArticle!: any;

  public showEditOptionArticleModal: boolean = false;

  public optionGroup: OptionGroup | null = null;

  public created() {
    this.resetOptions();
    this.setOptionFilter({});
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public async destroy(opt: OptionGroup) {
    this.$startLoading('option');
    await this.deleteOption({ id: opt._id });
    this.$stopLoading('option');
  }

  public async destroyGroup(optIds: string[]) {
    this.$startLoading('options');
    await this.deleteOptions({ ids: optIds });
    await this.getOptions();
    this.$stopLoading('options');
  }

  public async toggleOptionVisibility({ item, value }: { item: OptionGroup; value: boolean }) {
    this.$startLoading('option.article');
    if (value) {
      this.hideOption({ venue: this.venue._id, options: [item._id] });
    } else {
      this.makeOptionVisible({ venue: this.venue._id, options: [item._id] });
    }
    this.$stopLoading('option.article');
  }

  public async toggleVisibility({ item, value }: { item: OptionArticle; value: boolean }) {
    this.$startLoading('option.article');
    if (value) {
      this.hideOptionArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      this.makeArticleOptionVisible({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('option.article');
  }

  public async toggleActivation({ item, value }: { item: OptionArticle; value: boolean }) {
    this.$startLoading('option.article');
    if (value) {
      this.deactivateOptionArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      this.activateOptionArticle({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('option.article');
  }

  public async onSearch(searchOptions: { search: string; filterOptions: boolean }) {
    this.setOptionFilter({ ...this.optionFilter, ...searchOptions });
    this.getOptions();
  }

  public setOptionSearchFilter() {
    if (!this.$route.query) return;

    if (this.$route.query.search) {
      this.setOptionFilter({
        ...this.optionFilter,
        search: String(this.$route.query.search),
      });
    }

    if (this.$route.query.filterOptions) {
      this.setOptionFilter({
        ...this.optionFilter,
        filterOptions: this.$route.query.filterOptions,
      });
    }
  }

  public async onPageChange(page: Page) {
    if (!this.venue?._id) return;
    if (page.limit) {
      this.setOptionFilter({ ...this.optionFilter, ...{ venue: this.venue._id, page: page.page, limit: page.limit } });
    }
    this.setOptionSearchFilter();
    this.$startLoading('option');
    await this.getOptions(page.page);
    this.$stopLoading('option');
  }

  public async mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public handleOptionGroupCell(event: OptionGroup) {
    this.setActiveOption(event);
    this.optionGroup = event;
    this.showEditOptionArticleModal = true;
  }

  public async handleCancelOptionArticle() {
    this.showEditOptionArticleModal = false;
    this.optionGroup = null;
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      if (!this.$isLoading('option')) {
        this.setOptionFilter({ venue: this.venue._id, limit: 20 });
        this.setOptionSearchFilter();
        this.$startLoading('option');
        await this.getOptions(1);
        this.$stopLoading('option');
      }
    }
  }
}
