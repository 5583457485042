
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VCol, VRow } from 'vuetify/lib';

@Component({
  components: { VCol, VRow },
})
export default class OptionArticleDialog extends Vue {
  @Prop({ type: Object }) public item!: any;
  @Prop({ type: [Function, Boolean], default: true }) public editable!: boolean | ((item: any) => boolean);

  public show = false;
  public activePanel: number | null = null;
  public origin: string = '';

  public async toggleOptionArticleVisibility(value: Boolean, item: Partial<any>) {
    this.$emit('toggleOptionArticleVisibility', { value, item, showNotify: false, origin: this.origin });
  }

  public setShowModal(showModal: boolean = true) {
    this.show = showModal;
  }

  public setOrigin(origin: string = '') {
    this.origin = origin;
  }
}
