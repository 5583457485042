
import { GroupedArticle } from '@/interfaces/models/GroupedArticle';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VCol, VRow } from 'vuetify/lib';

@Component({
  components: { VCol, VRow },
})
export default class ArticleItem extends Vue {
  @Prop({ type: Object, required: true }) public item!: GroupedArticle;
  @Prop({ type: [Function, Boolean], default: true }) public editable!: boolean | ((item: any) => boolean);

  public async toggleVisibility(item: GroupedArticle) {
    this.$emit('toggleVisibility', { value: !item?.visible, item });
  }

  public toggleOptions(item: GroupedArticle) {
    this.$emit('toggleOptions', { item });
  }
}
