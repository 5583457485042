
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Category } from '@/interfaces/models/Category';
import { Permission } from '@/enums/Permission';
import { VExpansionPanels, VExpansionPanel, VSwitch } from 'vuetify/lib';
import { StockManagementType } from '@/enums/StockManagementType';
import ArticleManagerFilter from '@/components/articleManager/ArticleManagerFilter.vue';
import ArticleManagerLoader from '@/components/articleManager/ArticleManagerLoader.vue';
import Filter from '@/interfaces/api/Filter';
import ArticleManagerTableHeader from '@/components/articleManager/ArticleManagerTableHeader.vue';
import ArticleItem from '@/components/articleManager/ArticleItem.vue';
import OptionArticleItem from '@/components/articleManager/OptionArticleItem.vue';
import OptionArticleDialog from '@/components/articleManager/OptionArticleDialog.vue';
import SameArticlesDialog from '@/components/articleManager/SameArticlesDialog.vue';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { GroupedOptionArticle } from '@/interfaces/models/GroupedOptionArticle';
import { GroupedArticle } from '@/interfaces/models/GroupedArticle';
import FoodcardApiService from '@/api/http/FoodcardApiService';

const foodcard = namespace('foodcard');
const venue = namespace('venue');
const articleManager = namespace('articleManager');

@Component({
  components: {
    VWrapper,
    VExpansionPanels,
    VExpansionPanel,
    ArticleManagerFilter,
    VSwitch,
    ArticleManagerLoader,
    ArticleManagerTableHeader,
    ArticleItem,
    OptionArticleItem,
    OptionArticleDialog,
    SameArticlesDialog,
  },
})
export default class ArticleManager extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;
  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.State('categories') public categories!: Category[];

  @articleManager.State('filter') public filter!: Filter;
  @articleManager.Getter('articles') public articles!: GroupedArticle[];
  @articleManager.Getter('optionArticles') public optionArticles!: GroupedOptionArticle[];

  @articleManager.Action('makeArticleVisible') public makeArticleVisible!: any;
  @articleManager.Action('hideArticle') public hideArticle!: any;
  @articleManager.Action('hideOptionArticle') public hideOptionArticle!: any;
  @articleManager.Action('makeArticleOptionVisible') public makeArticleOptionVisible!: any;
  @articleManager.Action('setGroupedArticle') protected setGroupedArticle!: any;
  @articleManager.Action('setFilter') public setArticleFilter!: any;
  @articleManager.Action('resetFilter') public resetFilter!: any;
  @articleManager.Action('resetArticles') public resetArticles!: any;
  @articleManager.Action('fetchArticleVisibility') public fetchArticleVisibility!: any;
  @articleManager.State((state) => state.articlePagination.total) public totalArticle!: number;
  @articleManager.State((state) => state.articlePagination.pages) public pagesArticle!: number;
  @articleManager.State((state) => state.optionAtriclePagination.total) public totalOptionAtricle!: number;
  @articleManager.State((state) => state.optionAtriclePagination.pages) public pagesOptionAtricle!: number;

  public $refs!: {
    optionArticleDialog: InstanceType<typeof OptionArticleDialog> & { optionArticleDialog: any };
    sameArticlesDialog: InstanceType<typeof SameArticlesDialog> & { sameArticlesDialog: any };
    articleManagerFilter: InstanceType<typeof ArticleManagerFilter> & { articleManagerFilter: any };
  };
  public activeArtice: any | null = null;
  public showIfSameArticleModal = false;

  public itemsPerPage: number = 15;
  public pageArticle: number = 1;
  public pageOptionArticle: number = 1;

  private api: FoodcardApiService = new FoodcardApiService();

  public async mounted(): Promise<void> {
    this.resetFilter();
    this.resetArticles();
    if (!this.venue) return;
    await this.fetchData();
  }

  public beforeDestroy() {
    this.resetFilter();
    this.resetArticles();
  }

  public async loadMoreArticles() {
    if (this.$isLoading('articleManager') || this.pageArticle === this.pagesArticle) return;

    if (this.pageArticle <= this.pagesArticle) {
      this.$startLoading('loadMore');
      this.pageArticle++;
      this.setArticleFilter({ ...this.filter, page: this.pageArticle, type: StockManagementType.ARTICLES });
      await this.fetchArticleVisibility({ venue: this.venue._id });
      this.$stopLoading('loadMore');
    }
  }

  public async loadMoreOptionArticles() {
    if (this.$isLoading('articleManager') || this.pageOptionArticle === this.pagesOptionAtricle) return;

    if (this.pageOptionArticle <= this.pagesOptionAtricle) {
      this.$startLoading('loadMore');
      this.pageOptionArticle++;
      this.setArticleFilter({
        ...this.filter,
        page: this.pageOptionArticle,
        type: StockManagementType.OPTION_ARTICLES,
      });
      await this.fetchArticleVisibility({ venue: this.venue._id });
      this.$stopLoading('loadMore');
    }
  }

  public async fetchData() {
    if (!this.venue?._id || this.$isLoading('categories')) return;
    try {
      this.$startLoading('categories');
      if (this.$can(Permission.CATEGORY_VIEW)) {
        this.setCategoryFilter({ venue: this.venue._id, articles: true });
        await this.getCategories();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.$stopLoading('categories');
    }
  }

  public async onSearch(queries: any) {
    if (!this.$isLoading('articleManager')) {
      this.resetArticles();
      this.pageArticle = 1;
      this.pageOptionArticle = 1;

      this.$startLoading('articleManager');

      if (!queries?.type) {
        await this.handleSearchForType(queries, StockManagementType.ARTICLES, this.pageArticle);
        await this.handleSearchForType(queries, StockManagementType.OPTION_ARTICLES, this.pageOptionArticle);
      } else {
        await this.handleSearchForType(queries, queries.type, 1);
      }

      this.$stopLoading('articleManager');
    }
  }

  private async handleSearchForType(queries: any, type: StockManagementType, page: number) {
    this.setArticleFilter({
      ...queries,
      type,
      page,
      limit: this.itemsPerPage,
    });
    await this.fetchArticleVisibility({ venue: this.venue._id });
  }

  public toggleOptions(data: { item: any; origin: string }) {
    this.activeArtice = JSON.parse(JSON.stringify(data.item));
    this.$refs.optionArticleDialog.setOrigin('sameArticleModal');
    this.$refs.optionArticleDialog.setShowModal(true);
  }

  public async toggleOptionArticleVisibility(data: { value: Boolean; item: GroupedOptionArticle }) {
    try {
      const { articles, optionArticles } = await this.getSameArticleByNumber(data.item.optionNumber);

      if (articles?.length > 0 || optionArticles?.length > 1) {
        this.$refs.sameArticlesDialog.setData({
          articles,
          optionArticles,
          article: data.item,
          type: StockManagementType.OPTION_ARTICLES,
        });
        this.$refs.sameArticlesDialog.setShowModal(true);
        return;
      }
    } catch (e) {}
    this.$startLoading('articleManager');
    if (data.value) {
      await this.makeArticleOptionVisible({ venue: this.venue._id, articles: [data?.item?._id] });
    } else {
      await this.hideOptionArticle({ venue: this.venue._id, articles: [data.item._id] });
    }
    this.$stopLoading('articleManager');
  }

  public async simpleToggleOptionArticleVisibility(data: {
    value: boolean;
    item: any;
    showNotify: any;
    origin: string | undefined;
  }) {
    this.$startLoading('articleManager');
    if (data.value) {
      await this.makeArticleOptionVisible({ venue: this.venue._id, articles: [data.item._id] });
    } else {
      await this.hideOptionArticle({ venue: this.venue._id, articles: [data.item._id] });
    }

    if (!data.origin) {
      this.updateArticleVisibility(this.activeArtice, data.item._id, data.value);
    }
    if (data.origin === 'sameArticleModal') {
      this.updateOptionArticleVisibility(this.activeArtice, data.item._id, data.value);
      this.$refs.sameArticlesDialog.changeOptionArticleVisibility(data);
    }

    if (data.showNotify) {
      this.$refs.sameArticlesDialog.setShowModal(false);
      this.notifyInfo('articleManager.notification.saved');
    }
    this.$stopLoading('articleManager');
  }

  public updateArticleVisibility(data: any, id: string, value: boolean): void {
    for (const group of data?.groups) {
      const article = group.articles.find((article: any) => article._id === id);
      if (article) {
        article.visible = value;
        break;
      }
    }
  }

  public updateOptionArticleVisibility(data: any, id: string, value: boolean): void {
    data?.groups?.forEach((group: any) => {
      group?.articles?.forEach((optArticle: any) => {
        if (id === optArticle?._id) {
          optArticle.visible = value;
        }
      });
    });
  }

  public async toggleVisibility(data: { value: boolean; item: GroupedArticle }) {
    try {
      const { articles, optionArticles } = await this.getSameArticleByNumber(data.item.number);

      if (articles?.length > 1 || optionArticles?.length > 0) {
        this.$refs.sameArticlesDialog.setData({
          articles,
          optionArticles,
          article: data.item,
          type: StockManagementType.ARTICLES,
        });
        this.$refs.sameArticlesDialog.setShowModal(true);
        return;
      }
    } catch (e) {}

    this.$startLoading('articleManager');
    if (data.value) {
      await this.makeArticleVisible({ venue: this.venue._id, articles: [data.item._id] });
    } else {
      await this.hideArticle({ venue: this.venue._id, articles: [data.item._id] });
    }
    this.$stopLoading('articleManager');
  }

  public async simpleToggleVisibility(data: { value: boolean; item: any; showNotify: any }) {
    this.$startLoading('articleManager');
    if (data.value) {
      await this.makeArticleVisible({ venue: this.venue._id, articles: [data.item._id] });
    } else {
      await this.hideArticle({ venue: this.venue._id, articles: [data.item._id] });
    }
    this.$refs.sameArticlesDialog.changeArticleVisibility(data);
    if (data.showNotify) {
      this.$refs.sameArticlesDialog.setShowModal(false);
      this.notifyInfo('articleManager.notification.saved');
    }
    this.$stopLoading('articleManager');
  }

  public async toggleAllVisibility(data: { value: boolean; articles: any[]; optionArticles: any[] }) {
    const articles: string[] = data.articles.map((item) => item._id);
    const optionArticles: string[] = data.optionArticles.map((item) => item._id);

    this.$startLoading('articleManager');
    if (articles.length) {
      if (data.value) {
        await this.makeArticleVisible({ venue: this.venue._id, articles });
      } else {
        await this.hideArticle({ venue: this.venue._id, articles });
      }
    }

    if (optionArticles.length) {
      if (data.value) {
        await this.makeArticleOptionVisible({ venue: this.venue._id, articles: optionArticles });
      } else {
        await this.hideOptionArticle({ venue: this.venue._id, articles: optionArticles });
      }
    }
    this.notifyInfo('articleManager.notification.saved');
    this.$refs.sameArticlesDialog.setShowModal(false);
    this.$stopLoading('articleManager');
  }

  public canToggleArticleVisibility(val: boolean) {
    if (val) {
      return this.$can(Permission.ARTICLE_VISIBLE);
    }
    return this.$can(Permission.ARTICLE_HIDE);
  }

  public canToggleOptionArticleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_VISIBLE);
    }
    return this.$can(Permission.OPTION_ARTICLE_HIDE);
  }

  public async getSameArticleByNumber(number: string) {
    const { _id: venueId } = this.venue;

    const [articlesRes, optionArticlesRes] = await Promise.all([
      this.api.getGroupedArticlesByNumber(venueId, number),
      this.api.getGroupedOptionArticlesByNumber(venueId, number),
    ]);

    return { articles: articlesRes.data, optionArticles: optionArticlesRes.data };
  }

  @Watch('venue')
  public async onVenueChange() {
    if (!this.venue) return;
    this.resetArticles();
    this.resetFilter();
    // this.$refs.articleManagerFilter.reset();
    await this.fetchData();
  }
}
