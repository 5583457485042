
import { StockManagementType } from '@/enums/StockManagementType';
import { Article } from '@/interfaces/models/Article';
import { GroupedOptionArticle } from '@/interfaces/models/GroupedOptionArticle';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VCol, VRow } from 'vuetify/lib';

interface GroupedArticle {
  parentName: string;
  connectedArticles: { de: string }[];
  parentDescription: string;
  article: Article;
  type: StockManagementType;
}

@Component({
  components: { VCol, VRow },
})
export default class OptionArticleItem extends Vue {
  @Prop({ type: Object, required: true }) public item!: GroupedOptionArticle;
  @Prop({ type: [Function, Boolean], default: true }) public editable!: boolean | ((item: any) => boolean);

  public async toggleOptionArticleVisibility(item: GroupedOptionArticle) {
    this.$emit('toggleOptionArticleVisibility', { value: !item?.visible, item });
  }
}
