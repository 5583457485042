export default [
  {
    text: 'option.table.name',
    value: 'name',
  },
  {
    text: 'option.table.description',
    value: 'description',
    hideOnMobile: true,
  },
  {
    text: 'option.table.requiredAmount',
    value: 'requiredAmount',
  },
  {
    text: 'option.table.article',
    value: 'articles',
    type: 'slot',
  },
  {
    text: 'option.table.limit',
    value: 'limit',
    hideOnMobile: true,
  },
  {
    text: 'option.table.sortOrder',
    value: 'sortOrder',
    hideOnMobile: true,
  },
  {
    text: 'option.table.hasMultiple',
    value: 'hasMultiple',
    type: 'boolean',
    hideOnMobile: true,
  },
  {
    text: 'option.table.visible',
    value: 'visible',
    default: true,
    type: 'slot',
  },
];
