
import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import VTable from '../../shared/table/VTable.vue';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import optionHeaders from './headers';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import { mixins } from 'vue-class-component';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import typeSortByPrice from './sortByPrice';
import typeSortByVisibility from './sortByVisibility';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import _ from 'lodash';
import { Category } from '@/interfaces/models/Category';
import { Tag } from '@/interfaces/models/Tag';
import { decimalAdjust } from '@/util/helper';

@Component({
  components: { VColumnSelect, VTable, VItemSelect },
})
export default class OptionArticleSimpleTable extends mixins(EditArticleMixin) {
  @Prop({ type: Array, required: true }) public items!: OptionArticle[];
  @Prop({ type: String, default: '6' }) public offset!: number;
  @Prop({ type: Object, default: {} }) public option!: OptionGroup;
  @Prop({ type: Array, required: true }) public categories!: Category[];
  @Prop({ type: Array, required: false }) public tags!: Tag[];

  public sourceHeaders = optionHeaders;
  public headers: ColumnHeader[] = [];
  public sourceTypePrice = typeSortByPrice;
  public sourceTypeVisibility = typeSortByVisibility;
  public search = '';
  public number = '';
  public category: string | null = '';

  get sorted() {
    return this.items;
  }

  public handleAsset(event: { venue: Venue; asset: string }) {
    if (this.option && event.venue && event.asset) {
      this.updateOptionArticleAsset(this.option._id, event.venue._id, event.asset);
    }
  }

  public canToggleActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_DEACTIVATE);
    }
    return this.$can(Permission.OPTION_ARTICLE_ACTIVATE);
  }

  public canToggleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_VISIBLE);
    }
    return this.$can(Permission.OPTION_ARTICLE_HIDE);
  }

  public onChangeSearch = _.debounce((searchTerm: any) => {
    this.$emit('search', { search: searchTerm });
  }, 500);

  public handleSortPriceType(event: string) {
    this.$emit('search', { priceSort: event });
  }

  public handleSortVisibilityType(event: string | undefined) {
    this.$emit('search', { filterVisible: event });
  }

  public taxTakeawayToPercent(tax: { $numberDecimal: number }) {
    if (tax.hasOwnProperty('$numberDecimal')) {
      if (tax && tax?.$numberDecimal?.toString() === '-1') {
        return '';
      }
      if (tax && tax.$numberDecimal) {
        const taxNumber = Number(tax.$numberDecimal);
        return `${decimalAdjust('round', taxNumber * 100, -1)}%`;
      }
    } else {
      const taxNumber = Number(tax);
      return tax && tax.toString() !== '-1' && typeof tax === 'string'
        ? `${decimalAdjust('round', taxNumber * 100, -1)}%`
        : '';
    }

    return '-1';
  }

  public filterTagsByIds(tags: any) {
    let filterTags: Tag[] = [];
    if (this.isTag(tags && tags[0] ? tags[0] : {})) {
      filterTags = this.tags.filter((tag: Tag) => !!tags?.find((selectedTag: Tag) => selectedTag._id === tag._id));
    } else {
      filterTags = this.tags.filter((tag: Tag) => !!tags?.find((tagId: string) => tagId === tag._id));
    }
    return filterTags
      .map((tag: Tag) => {
        return this.$options.filters!.localized(tag.name);
      })
      .toString();
  }

  private isTag(obj: any): obj is Tag {
    return typeof obj === 'object' && obj !== null && '_id' in obj;
  }

  @Watch('category')
  @Emit('category')
  public onCategoryChange() {
    this.search = '';
    return this.category ? this.category : null;
  }
}
