
import { StockManagementType } from '@/enums/StockManagementType';
import { GroupedArticle } from '@/interfaces/models/GroupedArticle';
import { GroupedOptionArticle } from '@/interfaces/models/GroupedOptionArticle';
import { Component, Vue } from 'vue-property-decorator';
import { VCol, VRow } from 'vuetify/lib';

@Component({
  components: { VCol, VRow },
})
export default class SameArticlesDialog extends Vue {
  public active: any | null = null;
  public articles: GroupedArticle[] = [];
  public optionArticles: GroupedOptionArticle[] = [];
  public typeArticle: StockManagementType | null = null;
  public show = false;
  public activePanel: number | null = null;

  public async toggleOptionArticleVisibility(value: Boolean, item: any) {
    this.$emit('toggleOptionArticleVisibility', { value, item, origin: 'sameArticleModal' });
  }

  public async toggleVisibility(value: Boolean, item: any) {
    this.$emit('toggleVisibility', { value, item });
  }

  public toggleOptions(item: any) {
    this.$emit('toggleOptions', { item, origin: 'sameArticleModal' });
  }

  public toggleAllVisibility() {
    this.$emit('toggleAllVisibility', {
      value: !this.active?.visible,
      articles: this.articles,
      optionArticles: this.optionArticles,
    });
  }

  public toggleActiveVisibility() {
    if (this.typeArticle === StockManagementType.ARTICLES) {
      this.$emit('toggleVisibility', { value: !this.active?.visible, item: this.active, showNotify: true });
      return;
    }

    if (this.typeArticle === StockManagementType.OPTION_ARTICLES) {
      this.$emit('toggleOptionArticleVisibility', {
        value: !this.active?.visible,
        item: this.active,
        showNotify: true,
        origin: 'sameArticleModal',
      });
    }
  }

  public setData(data: { articles: any[]; optionArticles: any[]; article: any; type: StockManagementType }) {
    this.active = data.article;
    this.articles = data.articles;
    this.optionArticles = data.optionArticles;
    this.typeArticle = data.type;
  }
  public changeArticleVisibility(data: { value: boolean; item: GroupedArticle; showNotify: any }) {
    for (const article of this.articles) {
      if (article._id === data.item._id) {
        article.visible = data.value;
        break;
      }
    }
  }
  public changeOptionArticleVisibility(data: { value: boolean; item: GroupedOptionArticle; showNotify: any }) {
    const optionArticle = this.optionArticles.find((article) => article._id === data.item._id);
    if (optionArticle) {
      optionArticle.visible = data.value;
      return;
    }

    for (const article of this.articles || []) {
      for (const group of article?.groups || []) {
        const optArticle = group?.articles?.find((optArticle) => optArticle?._id === data.item._id);
        if (optArticle) {
          optArticle.visible = data.value;
          return;
        }
      }
    }
  }
  public setShowModal(showModal: boolean = true) {
    this.show = showModal;
  }
  public close() {
    this.show = false;
  }
}
